import React, { setGlobal, getGlobal } from 'reactn'
import { useNavigate } from 'react-router-dom'
import { MdHouse, MdPerson } from 'react-icons/md'
import pkg from '../../package.json'
import { persistState } from '../Services'
import './css/Sidebar.css'

const Sidebar = ({ companyName, user, strutture, selected, selectBaby, showModalStrutture, logout }) => {
  const navigate = useNavigate()
  const { impersonate } = getGlobal()

  return (
    <div className='sidebar'>
      <h4 style={{ color: 'white', marginLeft: 15, marginBottom: 0, marginTop: 20 }}>Benvenuto,</h4>
      <h6 style={{ color: 'white', marginLeft: 15, marginBottom: 0, marginTop: 0 }}>al baby {companyName || '****'}</h6>

      <div className='sideuserbox'>
        <div className='usernamebox'>
          <span className='usernameemail'>{user ? user.split('@')[0] : ''}</span><br />
          <span className='usernamedomain'>@{user ? user.split('@')[1] : ''}</span>
          {impersonate && <span className='usernamedomain' style={{ marginTop: 10, fontSize: 14 }}>Impersonando {impersonate.email}</span>}
        </div>
      </div>

      <h4 style={{ marginLeft: 15, color: 'white' }}>Strutture</h4>
      <hr />

      {
        strutture && strutture.length > 0 && strutture.map((b, i) => {
          return (
            <div key={i} className={selected === b._id ? 'sideitemboxselected' : 'sideitembox'} onClick={() => selectBaby(b._id)}>
              <MdHouse />
              <span>{b.name}</span>
            </div>
          )
        })
      }

      <br />
      <h4 style={{ marginLeft: 15, color: 'white' }}>Anagrafiche</h4>
      <hr />

      <div
        className={selected === 'bambini' ? 'sideitemboxselected' : 'sideitembox'}
        onClick={async () => {
          await setGlobal({ anagrafica: 'lowusers' }, persistState)
          navigate('/users')
        }}
      >
        <MdPerson />
        <span>Bambini</span>
      </div>
      <div
        className={selected === 'utenti' ? 'sideitemboxselected' : 'sideitembox'}
        onClick={async () => {
          await setGlobal({ anagrafica: 'highusers' })
          navigate('/users')
        }}
      >
        <MdPerson />
        <span>Collaboratori</span>
      </div>
      <div
        className={selected === 'strutture' ? 'sideitemboxselected' : 'sideitembox'}
        onClick={() => showModalStrutture()}
      >
        <MdHouse />
        <span>Strutture</span>
      </div>

      <span onClick={() => logout()} style={{ position: 'fixed', bottom: 10, left: 10, color: 'white', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>Logout</span>
      <span style={{ position: 'fixed', bottom: 10, left: 80, color: 'white', fontWeight: 'bold' }}>v{pkg.version}</span>
    </div>
  )
}

export default Sidebar
