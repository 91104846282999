import React, { Component } from 'reactn'
import { MdPerson } from 'react-icons/md'
import { persistState, clearState } from '../../../Services'
import { WithRouter } from '../../../Components'
import waveup from '../../../Assets/waveup.png'
import '../../Common.css'
import './Child.css'

class Parent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      children: [],
      name: ''
    }
  }

  componentDidMount () {
    const img = new window.Image()
    img.src = waveup

    const { user: { name, children } } = this.global
    this.setState({ children: children || [], name })
  }

  logout = () => {
    this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { children, name } = this.state

    return (
      <div className='child'>
        <div className='childpage'>
          <div className='childheader'>
            <h2 style={{ marginTop: 20 }}>Benvenuto, {name.split(' ')[0]}!</h2>
            <span>Seleziona il tuo bambino prima di continuare.</span>
            <span className='childlogout' onClick={() => this.logout()}>Logout</span>
          </div>
          <div className='childbody'>
            {
              children && children.length > 0 && children.map((c, i) => (
                <div
                  key={i}
                  className='childbutton'
                  onClick={async () => {
                    await this.setGlobal({ child: c.id }, persistState)
                    this.props.navigate('/parent')
                  }}
                >
                  <MdPerson />
                  <span>{c.name}</span>
                </div>
              ))
            }
          </div>

          <img alt='' src={waveup} className='wave fixedbottom' />
        </div>
      </div>
    )
  }
}

export default WithRouter(Parent)
