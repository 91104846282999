import React, { Component } from 'reactn'
import { MdQueryStats, MdAlarm, MdArrowBack } from 'react-icons/md'
import { WithRouter } from '../../../Components'
import waveup from '../../../Assets/waveup.png'
import '../../Common.css'
import './Parent.css'

class Parent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      childrenName: ''
    }
  }

  componentDidMount () {
    const img = new window.Image()
    img.src = waveup

    const { child, user: { children } } = this.global
    if (children && children.length > 0) {
      const filtered = children.filter(c => c.id === child)
      const childrenName = filtered && filtered.length > 0 ? filtered[0].name : ''
      this.setState({ childrenName })
    }
  }

  render () {
    const { childrenName } = this.state

    return (
      <div className='par'>
        <div className='parpage'>
          <div className='parheader'>
            <div className='partitle' style={{ marginTop: 20 }}>
              <MdArrowBack size={24} onClick={() => this.props.navigate('/')} />
              <h2>Menu</h2>
              <span style={{ textAlign: 'right', width: '70%', fontSize: 18, fontWeight: 600 }}>👶 {childrenName}</span>
            </div>
            <span>In questa sezione puoi visualizzare le statistiche e le voci di spesa. Puoi inserire inoltre gli orari di ingresso del tuo bambino!</span>
          </div>

          <div className='parbody'>
            <div className='parbutton' onClick={() => this.props.navigate('/hours')}>
              <MdAlarm />
              <span>Orari di <br />ingresso<br /> e uscita</span>
            </div>

            <div className='parbutton' onClick={() => this.props.navigate('/stats')}>
              <MdQueryStats />
              <span>Statistiche</span>
            </div>
          </div>

          <img alt='' src={waveup} className='wave fixedbottom' />
        </div>
      </div>
    )
  }
}

export default WithRouter(Parent)
