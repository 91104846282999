import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import md5 from 'md5'
import Api from '../../../Services/Api'

export default class TableDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: null,
      soci: [],
      selectedSoci: [],
      showModalChild: false,
      children: []
    }
  }

  async componentDidMount () {
    const user = this.props.data
    this.setState({ user, children: user.children })
  }

  async passwordReset () {
    const { user } = this.state
    const hash = md5((user.email).toString())
    const password = hash.substring(hash.length - 8, hash.length)

    const res = await Api.patch(`/users/${user._id}`, {
      password,
      firstLogin: true
    })
    if (res.ok) {
      toast('Password reset effettuato con successo!', { type: 'success', autoClose: 2000 })

      const ret = await Api.post('/emails', {
        from: 'hello@winniearcobaleno.it',
        to: user.email,
        subject: 'Registrazione Baby Parking',
        register: {
          username: user.email,
          password
        }
      })

      if (ret.ok) {
        toast('Email di reset password inviata con successo!', {
          type: 'success',
          autoClose: 2000,
          onClose: async () => {
            await this.props.loadData()
          }
        })
      }
    } else {
      toast('Impossibile effettuare il password reset.', { type: 'error', autoClose: 2000 })
    }
  }

  async deleteUser () {
    const { user } = this.state
    const res = await Api.get('/tools/deleteuser', { userId: user._id })

    if (res.ok) {
      toast('Utente eliminato con successo!', { type: 'success', autoClose: 2000 })

      await Api.post('/emails', {
        from: 'hello@winniearcobaleno.it',
        to: user.email,
        subject: 'Eliminazione utente Baby Parking',
        text: 'Utente eliminato dalla piattaforma. Da adesso non è piu possibile accedere. Cordiali saluti.'
      })

      await this.props.loadData()
    } else {
      toast('Impossibile eliminare l\'utente selezionato.', { type: 'error', autoClose: 2000 })
    }
  }

  render () {
    const { container, save } = styles
    const { user } = this.state

    return (
      <div style={container}>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {user && user.role === 'user' && <Button style={{ ...save, color: 'rgba(20,20,20,0.8)' }} variant='info' onClick={() => this.props.updateUserData(user)}>Modifica Dati</Button>}
          {user && (user.role === 'user' || user.role === 'support') && <Button style={save} variant='warning' onClick={() => this.passwordReset()}>Password reset</Button>}
          {user && (user.role === 'user' || user.role === 'support') && <Button style={save} variant='danger' onClick={() => this.deleteUser()}>Elimina Utente</Button>}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%'
  },
  save: {
    width: 180,
    height: 40,
    margin: 8
  },
  box: {
    width: '50%'
  }
}
