import React, { Component } from 'reactn'
import {
  MdCalendarMonth,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdArrowBack,
  MdAlarm
} from 'react-icons/md'
import { MobileTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { Api } from '../../../Services'
import { WeekPicker, WithRouter } from '../../../Components'
import '../../Common.css'
import './Hours.css'
import { toast } from 'react-toastify'

class Hours extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hours: [],
      from: '',
      to: ''
    }
  }

  async fetchHours (from, to, copied, futureFrom) {
    const { user, child } = this.global
    const res = await Api.get('/hours', { user: user._id, from, to, child })

    if (res.ok && res.data.length > 0) {
      if (!copied) {
        this.setState({ hours: res.data })
        return
      }

      // reschedule hours copied from week before
      const hours = []
      let date = moment(futureFrom).format('YYYY-MM-DD')
      for (const hour of res.data) {
        hours.push({
          ...hour,
          convalidated: false,
          date
        })
        date = moment(date).add(1, 'days').format('YYYY-MM-DD')
      }

      this.setState({ hours })
    } else {
      const hours = []
      let date = futureFrom || from
      for (let i = 0; i < 5; i++) {
        date = moment(date).format('YYYY-MM-DD')
        hours.push({
          user: user._id,
          date,
          entrance: '',
          exit: '',
          meal: false,
          present: true,
          child,
          baby: user.baby,
          convalidated: false
        })
        date = moment(date, 'YYYY-MM-DD')
        date = date.add(1, 'day')
      }
      this.setState({ hours })
    }
  }

  updateHours (user, date, entrance, exit, meal, present) {
    const { hours } = this.state
    const { child, user: { baby } } = this.global

    const updatedHours = []
    for (const hour of hours) {
      if (hour.date === date) {
        updatedHours.push({
          user,
          date,
          entrance,
          exit,
          meal: meal || false,
          present,
          child,
          baby,
          convalidated: false
        })
      } else {
        updatedHours.push(hour)
      }
    }
    this.setState({ hours: updatedHours })
  }

  async saveHours () {
    const { hours } = this.state

    // check missing data
    for (const hour of hours) {
      const { date, entrance, exit, present } = hour

      if (present) {
        if (!date || !entrance || entrance === '' || !exit || exit === '') {
          toast('Si prega di completare la settimana corrente prima di salvare i dati.', { type: 'error', autoClose: 2000 })
          return
        }
      }
    }

    // store hours
    const res = await Api.post('/hours', hours)
    if (!res.ok) {
      toast('Si è verificato un errore nel salvataggio dell\'orario. Si prega di riprovare più tardi.', { type: 'error', autoClose: 2000 })
    } else {
      toast('L\'orario settimanale è stato salvato correttamente!', { type: 'success', autoClose: 1500 })
      const { from, to } = this.state
      await this.fetchHours(from, to)
    }
  }

  async copyWeek () {
    const { from, to } = this.state
    const _from = moment(from).subtract(7, 'days').format('YYYY-MM-DD')
    const _to = moment(to).subtract(7, 'days').format('YYYY-MM-DD')

    await this.fetchHours(_from, _to, true, from)
  }

  render () {
    const { hours } = this.state

    const getWeekday = (dateString) => {
      const date = new Date(dateString)
      const weekdays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
      return weekdays[date.getDay()]
    }

    const convertTimeStringToMoment = (timeString) => {
      return moment(timeString, 'HH:mm')
    }

    return (
      <div className='hour'>
        <div className='hourpage'>
          <div className='hourheader'>
            <div className='hourtop' style={{ marginTop: 20 }}>
              <MdArrowBack size={24} style={{ marginRight: 20 }} onClick={() => this.props.navigate('/parent')} />
              <h2>Settimana nr. 31</h2>
            </div>
            <div className='hourweek'>
              <span>Selezionare l'orario di ingresso e di uscita del figlio, specificando inoltre se desidera avere il pasto.</span>
              <div className='hourweekselector'>
                <WeekPicker
                  onChange={week => {
                    const from = moment(week.firstDay).format('YYYY-MM-DD')
                    const to = moment(week.lastDay).format('YYYY-MM-DD')
                    this.fetchHours(from, to)
                    this.setState({ from, to })
                  }}
                />
                <MdCalendarMonth />
              </div>
            </div>
          </div>

          <div className='hourbody'>

            {
              (!hours || hours.length === 0 || hours[0].entrance === '') &&
                <div className='hourbutton copy' onClick={async () => await this.copyWeek()}>
                  <span>Copia Settimana Precedente</span>
                </div>
            }

            {
              hours && hours.length > 0 && hours.map((item, i) => {
                const { user, date, entrance, exit, meal, present, convalidated } = item

                return (
                  <div key={i} className='hourtimeline'>
                    <div className='hourgraphic'>
                      <div className='hourbar' />
                      <div className='circle' />
                    </div>
                    <div className='hourcard'>
                      <span className='datetile'>{moment(date).format('DD/MM/YYYY').substring(0, 5)}</span>
                      <div className='hourcardheader'>
                        <span className='hourtitle'>{getWeekday(date)}</span>
                        {present && <div className='tilemeal green' onClick={() => !convalidated && this.updateHours(user, date, entrance, exit, meal, false)}><MdCheckBox /><span>Presente</span></div>}
                        {!present && <div className='tilemeal red' onClick={() => !convalidated && this.updateHours(user, date, entrance, exit, meal, true)}><MdCheckBoxOutlineBlank /><span>Assente</span></div>}
                        {meal && present && <div className='tilemeal' onClick={() => !convalidated && this.updateHours(user, date, entrance, exit, false, present)}><MdCheckBox /><span>Pasto</span></div>}
                        {!meal && present && <div className='tilemeal' onClick={() => !convalidated && this.updateHours(user, date, entrance, exit, true, present)}><MdCheckBoxOutlineBlank /><span>Pasto</span></div>}
                      </div>
                      <div className='hourdetail' style={{ display: present ? 'flex' : 'none' }}>
                        <div className='hourtime'>
                          <span>Ingresso</span>
                          <div className='hourclock'>
                            <MdAlarm />
                            <MobileTimePicker
                              className='timepicker'
                              openTo='hours'
                              ampm={false}
                              disabled={convalidated}
                              value={convertTimeStringToMoment(entrance)}
                              onChange={e => this.updateHours(user, date, moment(e).format('HH:mm'), exit, meal, present)}
                            />
                          </div>
                        </div>
                        <div className='hourtime'>
                          <span>Uscita</span>
                          <div className='hourclock'>
                            <MdAlarm />
                            <MobileTimePicker
                              className='timepicker'
                              openTo='hours'
                              ampm={false}
                              disabled={convalidated}
                              value={convertTimeStringToMoment(exit)}
                              onChange={e => this.updateHours(user, date, entrance, moment(e).format('HH:mm'), meal, present)}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        convalidated &&
                          <div className='conva'>
                            <div className='conva green'><span>Convalidato</span><MdCheckBox /></div>
                          </div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className='hourfooter'>
            <span className='tilebutton'>Premere a lato per<br />salvare le modifiche</span>
            <div className='hourbutton' onClick={async () => await this.saveHours()}>
              <span>Salva</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithRouter(Hours)
