import React, { Component } from 'reactn'
import { Api, clearState, persistState } from '../../Services'
import md5 from 'md5'
import { toast } from 'react-toastify'
import { WithRouter } from '../../Components'

class Baby extends Component {
  constructor (props) {
    super(props)
    this.state = {
      companies: []
    }
  }

  async componentDidMount () {
    const res = await Api.get('/companies')
    if (res.ok) {
      this.setState({ companies: res.data })
    } else {
      toast('Impossibile recuperare le aziende dal db.', { type: 'error', autoClose: 2000 })
    }
  }

  async saveCompany () {
    const { name, piva, address, username, email } = this.state

    const hash = md5((email).toString())
    const password = hash.substring(hash.length - 8, hash.length)
    const role = 'admin'

    const res = await Api.post('/companies', {
      name,
      piva,
      address
    })
    if (res.ok) {
      toast('Azienda creata con successo!', { type: 'success', autoClose: 2000 })

      const ret = await Api.post('/users', {
        username,
        email,
        password,
        role,
        company: res.data._id,
        firstLogin: true
      })
      if (ret.ok) {
        toast('Utente admin registrato con successo!', { type: 'success', autoClose: 2000 })

        const ret = await Api.post('/emails', {
          from: 'hello@winniearcobaleno.it',
          to: email,
          subject: 'Registrazione Baby Parking',
          register: {
            username: email,
            password
          }
        })

        if (ret.ok) {
          toast('Email di registrazione Admin inviata con successo!', {
            type: 'success',
            autoClose: 2000,
            onClose: () => {
              window.location.reload()
            }
          })
        }
      } else {
        toast('Impossibile registrare il nuovo utente admin.', { type: 'error', autoClose: 2000 })
      }
    } else {
      toast('Impossibile registrare la nuova azienda.', { type: 'error', autoClose: 2000 })
    }
  }

  logout () {
    this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { companies } = this.state

    return (
      <div>
        <h1>Baby company selector</h1>

        <h4 onClick={() => this.logout()}>Logout</h4>

        <hr />
        <div>
          <span>Crea una nuova azienda</span>

          <span>Ragione Sociale</span>
          <input
            type='text'
            onChange={(e) => this.setState({ name: e.target.value })}
          />
          <span>Partita IVA</span>
          <input
            type='text'
            onChange={(e) => this.setState({ piva: e.target.value })}
          />
          <span>Indirizzo</span>
          <input
            type='text'
            onChange={(e) => this.setState({ address: e.target.value })}
          />
          <hr />
          <span>Nome admin</span>
          <input
            type='text'
            onChange={(e) => this.setState({ username: e.target.value })}
          />

          <span>Email admin</span>
          <input
            type='text'
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <hr />
          <button onClick={async () => await this.saveCompany()}>Salva</button>
        </div>

        <hr />
        <div>
          {
            companies && companies.length > 0 && companies.map((company, i) => {
              return (
                <button
                  key={i}
                  onClick={async () => {
                    await this.setGlobal({ company: company._id, impersonate: company.adminUser }, persistState)
                    this.props.navigate('/admin')
                  }}
                >
                  {company.name}
                </button>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default WithRouter(Baby)
