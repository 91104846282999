import React, { useState, useEffect } from 'react'
import { addMonths, subMonths, getDaysInMonth } from 'date-fns'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import { v4 } from 'uuid'
import './css/DatePicker.css'

const DatePicker = ({ onChange }) => {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(new Date())
  const [today, setToday] = useState(new Date())

  useEffect(() => {
    if (onChange) {
      onChange(today)
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [today])

  const isLeapYear = () => {
    const leapYear = new Date(new Date().getFullYear(), 1, 29)
    return leapYear.getDate() === 29
  }

  const convertDate = (date) => {
    const dt = new Date(date)
    return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`
  }

  const handleClick = (e) => {
    let localDate

    if (e.target.id.includes('prev')) {
      localDate = new Date(date.setDate(1))
      setDate(new Date(date.setDate(1)))
    } else if (e.target.id.includes('next')) {
      localDate = new Date(date.setDate(getDaysInMonth(date)))
      setDate(new Date(date.setDate(getDaysInMonth(date))))
    } else {
      localDate = new Date(date.setDate(e.target.id))
      setDate(new Date(date.setDate(e.target.id)))
    }
    // const firstDay = startOfWeek(localDate, { weekStartsOn: 1 })
    // const lastDay = endOfWeek(localDate, { weekStartsOn: 1 })

    setToday(localDate)
  }

  const months = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic'
  ]

  const days = {
    1: 31,
    2: isLeapYear() ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  }

  const renderDays = () => {
    const month = date.getMonth() + 1
    const ar = []
    for (let i = 1; i <= days[month]; i++) {
      const currentDate = new Date(date).setDate(i)

      let cName = 'single-number '
      if (
        new Date(today).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(today).getTime()
      ) {
        cName = cName + 'selected-week'
      }

      ar.push(
        <div key={v4()} id={i} className={cName} onClick={handleClick}>
          {i}
        </div>
      )
    }

    const displayDate = new Date(date).setDate(1)
    let dayInTheWeek = new Date(displayDate).getDay()
    if (dayInTheWeek < 1) {
      dayInTheWeek = 7
    }
    const prevMonth = []
    let prevMonthDays = new Date(date).getMonth()
    if (prevMonthDays === 0) {
      prevMonthDays = 12
    }
    for (let i = dayInTheWeek; i > 1; i--) {
      const previousMonth = new Date(date).setMonth(
        new Date(date).getMonth() - 1
      )
      const currentDate = new Date(previousMonth).setDate(
        days[prevMonthDays] - i + 2
      )

      let cName = 'single-number other-month'

      const currentTime = new Date(currentDate).getTime()
      const _today = new Date(today).getTime()
      // const endTime = new Date(week.lastDay).getTime()

      if (currentTime >= _today && currentTime <= _today) {
        cName = 'single-number selected-week'
      }

      prevMonth.push(
        <div
          // onClick={handleClick}
          key={v4()}
          id={'prev-' + i}
          className={cName}
        >
          {days[prevMonthDays] - i + 2}
        </div>
      )
    }

    const nextMonth = []
    let fullDays = 35
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = 'single-number other-month'
      const lastDay = today.getTime()
      const lastDayOfMonth = new Date(
        new Date(date).setDate(getDaysInMonth(date))
      )

      if (
        lastDayOfMonth.getTime() <= lastDay &&
        today.getMonth() === lastDayOfMonth.getMonth()
      ) {
        cName = 'single-number selected-week'
      }

      nextMonth.push(
        <div
          // onClick={handleClick}
          key={v4()}
          id={'next-' + i}
          className={cName}
        >
          {i}
        </div>
      )
    }
    return [...prevMonth, ...ar, ...nextMonth]
  }

  const handleDate = (next) => {
    let localDate = new Date(date)
    if (next) {
      localDate = addMonths(localDate, 1)
    } else {
      localDate = subMonths(localDate, 1)
    }
    setDate(new Date(localDate))
  }

  return (
    <div
      className='week-picker-display'
      onBlur={() => setOpen(false)}
      onClick={() => setOpen(true)}
      tabIndex={0}
    >
      <p>
        {/* Dal {convertDate(week.firstDay)} al {convertDate(week.lastDay)} */}
        {convertDate(today)}
      </p>
      {open && (
        <div className='week-picker-options'>
          <div className='title-week'>
            <div onClick={() => handleDate()} className='arrow-container'>
              <MdArrowBack />
            </div>
            {`${months[date.getMonth()]} ${date.getFullYear()}.`}
            <div onClick={() => handleDate(true)} className='arrow-container'>
              <MdArrowForward />
            </div>
          </div>
          <div className='numbers-container'>
            <div className='single-number day'>Lun</div>
            <div className='single-number day'>Mar</div>
            <div className='single-number day'>Mer</div>
            <div className='single-number day'>Gio</div>
            <div className='single-number day'>Ven</div>
            <div className='single-number day'>Sab</div>
            <div className='single-number day'>Dom</div>
          </div>
          <div className='numbers-container'>{renderDays()}</div>
        </div>
      )}
    </div>
  )
}

export default DatePicker
