import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'react-datetime/css/react-datetime.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='it'>
    <ToastContainer />
    <App />
  </LocalizationProvider>
)
