import React, { Component } from 'reactn'
import moment from 'moment'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
// import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import md5 from 'md5'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import Card from './Card'
import Api from '../../../Services/Api'

import TableDetail from './TableDetail'
import up from './Icons/up.png'
import down from './Icons/down.png'
import WithRouter from '../../../Components/WithRouter'
import './Users.css'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(30, 30, 30, 0.8)'
const DEF_STATE = {
  newUser: false,
  email: '',
  name: '',
  role: '',
  selectedBaby: '',
  newLowUser: false,
  bambini: [{ id: '', name: '' }],
  updateLowUser: false,
  updateUser: false,
  userToBePatched: ''
}

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users: [],
      baby: [],
      anagrafica: null,
      ...DEF_STATE
    }
  }

  async componentDidMount () {
    const { user, impersonate } = this.global

    let owner = user._id
    if (impersonate) {
      owner = impersonate._id
    }

    const ret = await Api.get('/baby', { owner })
    if (ret.ok) {
      this.setState({ baby: ret.data })
    } else {
      toast('Errore durante il recupero dei dati dei baby parking.', { type: 'error', autoClose: 2000 })
      return
    }

    await this.loadData()
  }

  async loadData () {
    const { anagrafica, user, impersonate } = this.global
    this.setState({ anagrafica })

    let company = user.company
    if (impersonate) {
      company = impersonate.company
    }

    let res = null
    if (anagrafica === 'lowusers') {
      res = await Api.get('/tools/lowusers', { company })
    } else {
      res = await Api.get('/tools/highusers', { company })
    }

    if (res.ok) {
      const _users = res.data.map(user => {
        let passwordTemp = ''
        if (user.firstLogin) {
          const hash = md5((user.email).toString())
          passwordTemp = hash.substring(hash.length - 8, hash.length)
        } else {
          passwordTemp = '******'
        }
        return {
          ...user,
          passwordTemp
        }
      })

      this.setState({ users: _users })
    } else {
      toast('Impossibile recuperare i dati degli utenti.', { type: 'error', autoClose: 2000 })
    }
  }

  async registerNewUser () {
    const { email, name, role, selectedBaby } = this.state
    if (email === '' || name === '' || role === '...') {
      toast('Si prega di verificare i dati inseriti prima di procedere.', { type: 'error', autoClose: 2000 })
    } else {
      const hash = md5((email).toString())
      const password = hash.substring(hash.length - 8, hash.length)

      const { user, impersonate } = this.global
      let company = user.company
      if (impersonate) {
        company = impersonate.company
      }

      const res = await Api.post('/users', {
        email,
        password,
        name,
        role,
        company,
        baby: [selectedBaby],
        firstLogin: true
      })
      if (res.ok) {
        toast('Collaboratore registrato con successo!', { type: 'success', autoClose: 2000 })

        const ret = await Api.post('/emails', {
          from: 'hello@winniearcobaleno.it',
          to: email,
          subject: 'Registrazione Baby Parking',
          register: {
            username: email,
            password
          }
        })

        if (ret.ok) {
          toast('Email di registrazione Collaboratore inviata con successo!', {
            type: 'success',
            autoClose: 2000,
            onClose: async () => {
              this.setState({ ...DEF_STATE })
              await this.loadData()
            }
          })
        }
      } else {
        toast('Impossibile registrare il collaboratore, si prega di riprovare.', { type: 'error', autoClose: 2000 })
      }
    }
  }

  async registerNewLowUser () {
    const { email, name, selectedBaby, bambini, updateLowUser } = this.state
    if (email === '' || name === '' || selectedBaby === '...' || bambini.filter(b => b === '').length > 0) {
      toast('Si prega di verificare i dati inseriti prima di procedere.', { type: 'error', autoClose: 2000 })
    } else {
      const hash = md5((email).toString())
      const password = hash.substring(hash.length - 8, hash.length)

      const children = bambini.map(b => {
        return {
          id: md5(b.name + Date.now().toString()),
          name: b.name
        }
      })

      const { user, impersonate } = this.global
      let company = user.company
      if (impersonate) {
        company = impersonate.company
      }

      if (updateLowUser) { // UPDATE USER INFOS
        const { userToBePatched } = this.state
        const res = await Api.patch(`/users/${userToBePatched}`, {
          email,
          name,
          children,
          baby: selectedBaby
        })
        if (res.ok) {
          toast('Genitore aggiornato con successo!', {
            type: 'success',
            autoClose: 2000,
            onClose: async () => {
              this.setState({ ...DEF_STATE })
              await this.loadData()
            }
          })
        } else {
          toast('Impossibile aggiornare il genitore, si prega di riprovare.', { type: 'error', autoClose: 2000 })
        }
      } else { // REGISTER NEW USER
        const res = await Api.post('/users', {
          email,
          password,
          name,
          role: 'user',
          children,
          company,
          baby: selectedBaby,
          firstLogin: true
        })
        if (res.ok) {
          toast('Genitore registrato con successo!', { type: 'success', autoClose: 2000 })

          const ret = await Api.post('/emails', {
            from: 'hello@winniearcobaleno.it',
            to: email,
            subject: 'Registrazione Baby Parking',
            register: {
              username: email,
              password
            }
          })

          if (ret.ok) {
            toast('Email di registrazione Utente inviata con successo!', {
              type: 'success',
              autoClose: 2000,
              onClose: async () => {
                this.setState({ ...DEF_STATE })
                await this.loadData()
              }
            })
          }
        } else {
          toast('Impossibile registrare il genitore, si prega di riprovare.', { type: 'error', autoClose: 2000 })
        }
      }
    }
  }

  render () {
    const customStyle = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        position: 'absolute',
        zIndex: 999999999999999,
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: 20
      },
      outline: 'none'
    }

    const styles = {
      column: {
        backgroundColor: '#B1AFFF',
        color: 'white',
        fontSize: 16
      },
      row: {
        color: 'white',
        height: 20
      },
      cell: {
        fontSize: 15,
        wordWrap: 'break-word'
      },
      container: {
        display: 'flex'
      },
      table: {
        width: '100%'
      },
      toolkit: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
      },
      exportBtn: {
        width: 100,
        height: 40,
        backgroundColor: 'red',
        color: 'white',
        marginRight: 10,
        marginTop: 5
      }
    }

    const fields = [
      {
        dataField: 'email',
        text: 'E-Mail',
        headerStyle: { ...styles.column, width: '20%' },
        sort: true,
        formatter: cell => renderCell(cell),
        align: 'left',
        key: 4
      },
      {
        dataField: 'passwordTemp',
        text: 'Password Temporanea',
        headerStyle: { ...styles.column, width: '8%' },
        sort: true,
        formatter: cell => renderCell(cell),
        align: 'left',
        key: 4
      },
      {
        dataField: 'name',
        text: 'Nome utente',
        headerStyle: { ...styles.column, width: '21%' },
        sort: true,
        formatter: cell => renderCell(cell),
        align: 'left',
        key: 4
      },
      {
        dataField: 'children',
        text: 'Bambino',
        headerStyle: { ...styles.column, width: '21%' },
        sort: true,
        formatter: cell => renderChildren(cell),
        align: 'left',
        key: 4
      },
      {
        dataField: 'role',
        text: 'Tipo',
        headerStyle: { ...styles.column, width: '10%' },
        sort: true,
        formatter: cell => renderCell(cell),
        align: 'left',
        key: 4
      },
      {
        dataField: 'baby',
        text: 'Baby  in cui lavora',
        headerStyle: { ...styles.column, width: '20%' },
        sort: true,
        formatter: cell => renderBaby(cell),
        align: 'left'
      }
    ]

    const { users, newUser, email, name, role, baby, selectedBaby, newLowUser, anagrafica, bambini, updateUser, updateLowUser } = this.state
    const { container, table, toolkit } = styles
    const { SearchBar, ClearSearchButton } = Search
    // const { ExportCSVButton } = CSVExport

    const defaultSorted = [{
      dataField: 'type',
      order: 'asc'
    }]

    const expandRow = {
      renderer: row =>
        <TableDetail
          data={row}
          loadData={async () => await this.loadData()}
          updateUserData={user => {
            const { baby } = this.state
            if (anagrafica === 'lowusers') {
              this.setState({
                newLowUser: true,
                updateLowUser: true,
                ...user,
                bambini: user.children,
                baby,
                selectedBaby: user.baby,
                userToBePatched: user._id
              })
            } else {
              this.setState({
                newUser: true,
                updateUser: true,
                ...user
              })
            }
          }}
        />,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      }
    }

    const rowEvents = {
      onClick: (e, row, rowIndex) => {}
    }

    const rowBackground = (row, rowIndex) => {
      const style = {}
      rowIndex % 2 === 0 ? style.backgroundColor = '#cfdede' : style.backgroundColor = '#f9f9f9'
      return style
    }

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      firstPageText: 'Inzio',
      prePageText: 'Indietro',
      nextPageText: 'Avanti',
      lastPageText: 'Fine',
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => {
        return (<i style={{ marginLeft: 10 }}>Records da <b>{from}</b> a <b>{to}</b> di <b>{size}</b> risultati</i>)
      },
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }]
    }

    const renderCell = (cell, type) => <p style={styles.cell}>{cell}</p>

    const renderBaby = (cell) => {
      if (cell) {
        const babyId = Array.isArray(cell) ? cell[0] : cell
        const baby = this.state.baby.filter(b => b._id === babyId)
        if (baby && baby.length > 0) {
          return <p style={styles.cell}>{baby[0].name}</p>
        }
        return <p style={styles.cell}>Baby xyz</p>
      }
    }

    const renderChildren = (cell) => {
      if (cell) {
        const label = cell.map(c => c.name).join(', ')
        return <p style={styles.cell}>{label}</p>
      }
    }

    return (
      <Card style={container}>
        <Card shadow style={table}>
          <div style={{ marginLeft: 20, marginTop: 10, marginBottom: -20 }}>
            <h4>Anagrafica di gestione degli utenti {anagrafica === 'lowusers' ? 'genitori' : 'collaboratori'}</h4>
          </div>
          <ToolkitProvider
            keyField='_id'
            data={users}
            columns={fields}
            style={{ width: '100%' }}
            rowEvents={rowEvents}
            loading
            striped
            hover
            search
            exportCSV={{
              fileName: `Utenti_${moment().format('YYYY-MM-DD')}.csv`
            }}
          >
            {
              props =>
                <Card style={{ width: '100%', padding: 20 }}>
                  <Card style={toolkit}>
                    <button
                      className='button'
                      onClick={() => {
                        const { user } = this.global
                        if (user.role === 'owner') {
                          this.props.navigate('/admin')
                        } else {
                          this.props.navigate('/')
                        }
                      }}
                    >
                      <i className='fas fa-arrow-left' />
                    </button>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
                      <SearchBar
                        {...props.searchProps}
                        placeholder='Inizia a digitare per cercare nella tabella...'
                        delay={50}
                        srText=''
                        style={{ width: 400, minWidth: 200, height: 40, marginRight: 10, marginTop: 10 }}
                      />
                      <ClearSearchButton {...props.searchProps} className='reset-btn' text='Reset' />
                      {/* <ExportCSVButton {...props.csvProps} style={{ width: 120, color: 'white', backgroundColor: '#DA7297' }}>Export CSV</ExportCSVButton> */}
                      <Button
                        style={{ backgroundColor: '#B1AFFF', border: 'solid 1px #B1AFFF' }}
                        onClick={() => {
                          if (anagrafica === 'lowusers') {
                            this.setState({ newLowUser: true })
                          } else {
                            this.setState({ newUser: true })
                          }
                        }}
                      >
                        Nuovo {anagrafica === 'lowusers' ? 'genitore' : 'collaboratore'}
                      </Button>
                    </div>
                  </Card>
                  <br />
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication='Non ci sono dati da visualizzare.'
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    expandRow={expandRow}
                    tabIndexCell
                    bootstrap4
                    defaultSorted={defaultSorted}
                    rowEvents={rowEvents}
                    rowStyle={rowBackground}
                  />
                </Card>
            }
          </ToolkitProvider>
        </Card>

        <Modal
          isOpen={newUser}
          style={customStyle}
          ariaHideApp={false}
          className='newUserModal'
          onRequestClose={() => this.setState({ ...DEF_STATE })}
        >
          <div style={{ backgroundColor: 'white', minWidth: 400, padding: 20, borderRadius: 20 }}>
            <p style={{ fontSize: 18, fontWeight: 'bold' }}>{updateUser ? 'Modifica' : 'Registrazione Nuovo'} Utente collaboratore</p>

            <InputGroup.Text>Email</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={email} onChange={(e) => this.setState({ email: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Nome</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={name} onChange={(e) => this.setState({ name: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Tipo utente</InputGroup.Text>
            <select value={role} onChange={e => this.setState({ role: e.target.value })} style={{ width: '100%', height: 40, marginBottom: 15 }}>
              <option value='...'>...</option>
              {/* <option value='admin'>Admin</option> */}
              <option value='support'>Collaboratore</option>
            </select>

            <InputGroup.Text>Baby sede di lavoro</InputGroup.Text>
            <select value={selectedBaby} onChange={e => this.setState({ selectedBaby: e.target.value })} style={{ width: '100%', height: 40, marginBottom: 15 }}>
              <option value='...'>...</option>
              {
                baby && baby.length > 0 && baby.map((b, i) => {
                  return (
                    <option key={i} value={b._id.toString()}>{b.name}</option>
                  )
                })
              }
            </select>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={async () => await this.registerNewUser()}
                style={{ marginLeft: 15, backgroundColor: '#B1AFFF', border: 'solid 1px #B1AFFF' }}
              >
                Registra collaboratore
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={newLowUser}
          style={customStyle}
          ariaHideApp={false}
          className='newUserModal'
          onRequestClose={() => this.setState({ ...DEF_STATE })}
        >
          <div style={{ backgroundColor: 'white', minWidth: 400, padding: 20, borderRadius: 20 }}>
            <p style={{ fontSize: 18, fontWeight: 'bold' }}>{updateLowUser ? 'Modifica' : 'Registrazione Nuovo'} Utente genitore</p>

            <InputGroup.Text>Email</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={email} onChange={(e) => this.setState({ email: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Nome Genitore</InputGroup.Text>
            <InputGroup className='mb-3'>
              <FormControl value={name} onChange={(e) => this.setState({ name: e.target.value })} />
            </InputGroup>

            <InputGroup.Text>Baby parking</InputGroup.Text>
            <select value={selectedBaby} onChange={e => this.setState({ selectedBaby: e.target.value })} style={{ width: '100%', height: 40, marginBottom: 15 }}>
              <option value='...'>...</option>
              {
                baby && baby.length > 0 && baby.map((b, i) => {
                  return (
                    <option key={i} value={b._id.toString()}>{b.name}</option>
                  )
                })
              }
            </select>

            <InputGroup.Text>Nome del Bambino</InputGroup.Text>
            {bambini && bambini.length > 0 && bambini.map((bambino, index) => {
              return (
                <div key={index} className='ipv4-input-group'>
                  <InputGroup className='mb-3'>
                    <FormControl
                      value={bambino.name}
                      onChange={(e) => {
                        const updatedList = [...bambini]
                        updatedList[index] = { id: bambino.id, name: e.target.value }
                        this.setState({ bambini: updatedList })
                      }}
                    />
                  </InputGroup>
                  <button type='button' onClick={() => this.setState({ bambini: [...bambini, { id: '', name: '' }] })} className='add-button'>+</button>
                  <button
                    type='button'
                    onClick={() => {
                      const updatedList = bambini.filter((_, i) => i !== index)
                      if (updatedList.length === 0) {
                        this.setState({ bambini: [{ id: '', name: '' }] })
                      } else {
                        this.setState({ bambini: updatedList })
                      }
                    }}
                    className='delete-button'
                  >
                    -
                  </button>
                </div>
              )
            })}

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={async () => await this.registerNewLowUser()}
                style={{ marginLeft: 15, backgroundColor: '#B1AFFF', border: 'solid 1px #B1AFFF' }}
              >
                {updateLowUser ? 'Modifica' : 'Registra'} genitore
              </Button>
            </div>
          </div>
        </Modal>
      </Card>
    )
  }
}

export default WithRouter(Users)
